import { useState } from 'react';
import { GenesInputBox } from './GenesInputBox';
import { DiseasesInputBox } from './DiseasesInputBox';
import { SubmitButton } from './SubmitButton';
import { GeneSearchModal } from './GeneSearchModal';
import { GeneValidationModal } from './GeneValidationModal';


export function CustomInput({api_root, token, handleSubmit}) {

  const handleValidateDone = (validGenesList, validatedDiseases) => {
    // console.log('validate done!')
    setGeneValidationModalShow(false)
    handleSubmit(validGenesList, validatedDiseases)
  }

  const [genesToValidate, setGenesToValidate] = useState([])
  const [validGenesList, setValidGenesList] = useState([])
  const [geneSearchModalShow, setGeneSearchModalShow] = useState(false)
  const [geneValidationModalShow, setGeneValidationModalShow] = useState(false)
  const [validatedDiseases, setValidatedDiseases] = useState([])

  const [notValidGenes, setNotValidGenes] = useState([])
  
  // console.log(genesToValidate)
  // console.log(validGenesList)
  // console.log(validatedDiseases)

  return (
    <div className='mt-2'>
      <GenesInputBox
        demo={false}
        setDemoModalShow={()=>null}
        setGeneSearchModalShow={setGeneSearchModalShow}
        genesToValidate={genesToValidate}
        setGenesToValidate={setGenesToValidate}
      />
      <DiseasesInputBox
        demo={false}
        setDemoModalShow={()=>null}
        setValidatedDiseases={setValidatedDiseases}
        token={token}
        api_root={api_root}
      />
      <SubmitButton
        genesList={genesToValidate}
        handleSubmit={setGeneValidationModalShow}
      />
      <GeneSearchModal
        show={geneSearchModalShow}
        onHide={() => setGeneSearchModalShow(false)}
        token={token}
        setGenesInputBoxContent={setGenesToValidate}
        genesInputBoxContent={genesToValidate}
        api_root={api_root}
      />
      <GeneValidationModal
        show={geneValidationModalShow}
        onHide={() => handleValidateDone(validGenesList, validatedDiseases)}
        content='test content'
        genes_list={genesToValidate}
        validatedGenes={validGenesList}
        setValidatedGenes={setValidGenesList}
        notValidGenes={notValidGenes}
        setNotValidGenes={setNotValidGenes}
        setInputGenes={setGenesToValidate}
        token={token}
        api_root={api_root}
      />
    </div>
  );
}
