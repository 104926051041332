import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ItemSearchMulti } from './ItemSearch'


function removeBlankLines(txt) {
  return txt.split('\n').map((x)=>x.trim()).filter((x)=>{return x !== ""}).join('\n')
}


export function GeneSearchModal(props) {
  // console.log(`modal show = ${props.show}`)
  // if (props.show) {
  //   console.log('call api to check genes')
  //   console.log(props.genes_list)
  // }

  const [outputGenes, setOutputGenes] = useState()

  function handleClose(genes_list)  {
    // console.log('handleClose')
    // console.log(genes_list)
    let joined_output = genes_list.join('\n')
    // console.log(joined_output)

    // console.log(props.genesInputBoxContent)
    props.setGenesInputBoxContent(removeBlankLines(props.genesInputBoxContent + '\n' + joined_output))
    props.onHide()
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Gene Search
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Centered Modal</h4> */}
        <ItemSearchMulti
          onOutputChangeHandle={setOutputGenes}
          token={props.token}
          api_root={props.api_root}
          item_type="gene"
          multi          
        ></ItemSearchMulti>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleClose(outputGenes)}>Add</Button>
      </Modal.Footer>
    </Modal>
  )
}
