import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { AsyncPaginate } from 'react-select-async-paginate'
import Table from 'react-bootstrap/Table'
import './animation.css'
import { SubmitButton } from './SubmitButton'

const elabRespGeneSet = async function(r) {

    const elab_r = r.map(({id, name}) => {
      return {
        'value' : id,
        'label' : `${id} | ${name}`
      }
    })
  
    return { options: elab_r}
    
}


const searchGeneset = async (source, q, token, api_root, text_mining=false, min_confidence_score=0.0) => {

  let request_url = `${api_root}/geneset/${source}/search?q=${q}&limit=100&offset=0`

  if (source === 'disease') {
    request_url = request_url + `&text_mining=${text_mining}`
    request_url = request_url + `&min_confidence_score=${min_confidence_score}`
  } 

  if (q.length > 0) {

    const resp = fetch(
      request_url,
      {
        'method' : 'GET',
        'headers' : {
          'authorization' : `Bearer ${token}`
        }
      }
    )
    
    const r = resp
      .then(response => response.json())
      .then(elabRespGeneSet)

    return r
  } else {
    return elabRespGeneSet([])
  }
}

const getGenesetGenes = (source, q, token, api_root, text_mining=false, min_confidence_score=0.0) =>  {

  let request_url = `${api_root}/geneset/${source}/list?id=${q}`

  if (source === 'disease') {
    request_url = request_url + `&text_mining=${text_mining}`
    request_url = request_url + `&min_confidence_score=${min_confidence_score}`
  } 

  const resp = fetch(
    request_url,
    {
      'method' : 'GET',
      'headers' : {
        'authorization' : `Bearer ${token}`
      }
    }
  )
    
  const r = resp
    .then(response => response.json())
    .then(elabGeneSetList)

  return r
}


const elabGeneSetList = async function(r) {

    const elab_r = r.map(({id, name, genes_list}) => {
      return genes_list
    })

    return elab_r
    
}

const joinGeneSets = async function(s) {
  const a = Array.from(new Set([].concat(...s)))
  return a
}

  


function GeneSetInput({source, source_name, token, api_root, handleSubmit, demo}) {

    const [pulse, setPulse] = useState('')
    const [genesList, setGenesList] = useState([])
    const [textMiningCheck, setTextMiningCheck] = useState(false)
    const loadOptions = async (q) => searchGeneset(source, q, token, api_root, textMiningCheck, 0.0)

    const handleAdd = (setIdList) => {
      setIdList = setIdList.map((x)=>x.value).join(',')
      getGenesetGenes(source, setIdList, token, api_root, textMiningCheck, 0.0).then(joinGeneSets).then(setGenesList)
    }

    let additional_options = null
    if (source === 'disease') {
      additional_options = <div>
        <Form.Check 
          type="checkbox"
          id="default-checkbox"
          label="Include text-mining"
          checked={textMiningCheck}
          onChange={(e)=>setTextMiningCheck(e.target.checked)}
        />
      </div>
    }

    const onInputChange = async (x) => {
      // setGenesetList(x) ;
      if (pulse === '') {
        setPulse('grpulse')
      } ;
      handleAdd(x)
    }
    
    return (
        <div className='p-2 flex'>
            <p>Add to the Input Genes a set of genes related to {source_name.toLowerCase()}.</p>
            {additional_options}
            <div className='row mb-2'>
              <AsyncPaginate
                  // className='col-10'
                  isMulti
                  placeholder={`Search for ${source_name.toLowerCase()}...`}
                  loadOptions={ loadOptions }
                  onChange={onInputChange}
                  debounceTimeout={300}
                  cacheUniqs={(source === 'disease') ? [textMiningCheck] : []}
                  isDisabled={demo}
              />
            </div>
            {
              (genesList.length > 0)? 
              <div className='overflow-auto mb-2' style={{'max-height':'350px'}}>
                <Table striped bordered hover size="sm" responsive>
                  <thead>
                    <tr>
                      <th>GeneSymbol</th>
                    </tr>
                  </thead>
                  <tbody > 
                    {
                      genesList.map(
                        (
                          r=>{
                            return (
                              <tr>
                              <td>{r}</td>
                            </tr>
                            )
                          }
                        )
                      ) 
                    }
                  </tbody>
                </Table> 
              </div> : null
            }
            <SubmitButton
              genesList={genesList}
              handleSubmit={handleSubmit}
            />
        </div>
    )
}

export default GeneSetInput