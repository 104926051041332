import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export function TablePreview({ data, fields, maxrows }) {

  const dynamicColumns = fields.map((f) => {
    return <Column key={f} field={f} header={f} sortable></Column>;
  });

  return (
    <div className='overflow-auto mt-2'>
      <DataTable value={data} responsiveLayout="scroll" paginator rows={maxrows}>
        {dynamicColumns}
      </DataTable>
    </div>
  );
}
