import { Button, Card } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';

export function FileDetails({ uploadedFile, hidden, onSelect, onRemove }) {

  let lastModDate = null
  let lastModTime = null
  let fileName = null
  let fileType = null
 
 if (uploadedFile !== null) {
    lastModDate = new Date(uploadedFile.lastModified).toLocaleDateString()
    lastModTime = new Date(uploadedFile.lastModified).toLocaleTimeString()
    fileType = uploadedFile.type
    fileName = uploadedFile.name
  }

  return (
    <Card hidden={hidden} className='mt-1'>
      <Card.Body>
        <div className='d-flex'>
          <Card.Title>{fileName}</Card.Title>
          <Button 
            className='ms-auto' 
            variant='danger'
            onClick={()=>onRemove()}
          ><XLg></XLg></Button>
        </div>
        <Card.Text>
          Type: {fileType}
          <br />
          Last Modified: {lastModDate} {lastModTime}
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <Button 
          className='w-100'
          variant='outline-success' 
          onClick={()=>onSelect(uploadedFile)}
        >Select</Button>
      </Card.Footer>
    </Card>
  )
}
