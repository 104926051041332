import ReactSelect from 'react-select';

export function HeaderSelector({ label, setHeader, options }) {
  return (
    <div className=''>
      <label htmlFor="formHeader" className="form-label">{label}</label>
      <ReactSelect
        id="formHeader"
        options={options}
        onChange={(x) => setHeader(x.value)} />
    </div>
  );
}
