import { Button } from "react-bootstrap";
import { Download } from "react-bootstrap-icons";

function SampleFileDownloader() {
    const file_content = `\
app
ace
apoe
mapt
psen1
adam10
bace1\
`

    const downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([file_content], {
            type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = "input_sample.txt";
        document.body.appendChild(element);
        element.click();
    };

    return (
        <Button variant='info' onClick={downloadTxtFile} className='w-100'> 
            <Download/> Sample File
        </Button>
    );
}

export default SampleFileDownloader