import 'bootstrap/dist/css/bootstrap.min.css';

import { useState } from 'react';
import { FileUpload } from './FileUpload';
import { InputBox } from './InputBox';
import { ResultPlot } from './ResultPlot';
import { ResultTable } from './ResultTable';
import { VulcanPlot, RankedVulcanPlot } from './VulcanPlot';


function App({token, api_root, debug}) {

  const box_classname = 'card p-3 h-100'
  
  const [vulcanPlotData, setVulcanPlotData] = useState([])
  const [rankedVulcanPlotData, setRankedVulcanPlotData] = useState([])
  const [pValueTh, setPValueTh] = useState(0)
  const [effectSizeTh, setEffectSizeTh] = useState(0)
  
  const [vulcanPlotIsHidden, setVulcanPlotIsHidden] = useState(true)
  const [inputBoxIsHidden, setInputBoxIsHidden] = useState(true)
  const [resultIsHidden, setResultIsHidden] = useState(true)

  const filteredVulcanPlotData = vulcanPlotData.filter(
    (d)=> {
      let cond_x = (d['logFC'] >= +effectSizeTh | d['logFC'] <= -effectSizeTh )
      let cond_y = (d['-log10(p)'] >= pValueTh )
      return cond_x & cond_y
    }
  )
  filteredVulcanPlotData.forEach(x=>x.color = x.logFC)

  const logfc_max = Math.ceil(Math.max(...vulcanPlotData.filter(x=>Number.isFinite(x.logFC)).map(x=>Math.abs(x.logFC)))) 
  const pvalue_max = Math.ceil(Math.max(...vulcanPlotData.filter(x=>Number.isFinite(x['-log10(p)'])).map(x=>x['-log10(p)']))) 

  return (
    <>
      <div className='row row-cols- mt-2 gx-2'>
        <div className='col'>
          <FileUpload
            className={box_classname}
            setSelVulcanPlotData={setVulcanPlotData}
            setVulcanPlotIsHidden={setVulcanPlotIsHidden}
            setInputBoxIsHidden={setInputBoxIsHidden}
            setResultIsHidden={setResultIsHidden}
          ></FileUpload>  
        </div>
      </div>
      {/* <h1 hidden={vulcanPlotIsHidden} className='text-light'>Input Section</h1> */}
      <div className='row row-cols-2 mt-2 gx-2'>
        <div className='col'>
          <VulcanPlot
            className={box_classname}
            hidden={vulcanPlotIsHidden}
            data={filteredVulcanPlotData}
            outputData={[]}
            logfc_max={logfc_max}
            pvalue_max={pvalue_max}
            thX={effectSizeTh}
            thY={pValueTh}
            setThX={setEffectSizeTh}
            setThY={setPValueTh}
          ></VulcanPlot>
        </div>
        <div className='col'>
          <InputBox
            className={box_classname}
            hidden={inputBoxIsHidden}
            data={filteredVulcanPlotData}
            setRankedData={setRankedVulcanPlotData}
            api_root={api_root}
            token={token}
            postSubmitHandle={()=>setResultIsHidden(false)}
          />
        </div>
      </div>
      {/* <h1 className='mt-2 bt-2' hidden={resultIsHidden}>Results Section</h1> */}
      <div className='row row-cols-2 mt-2 gx-2'>
        <div className='col'>
          <RankedVulcanPlot
            className={box_classname}
            hidden={resultIsHidden}
            data={rankedVulcanPlotData}
          />
        </div>
        <div className='col'>
          <ResultPlot
            className={box_classname}
            hidden={resultIsHidden}
            data={rankedVulcanPlotData.length === 0 ? [] : rankedVulcanPlotData}
            logx={false}
            logy={false}
          />
        </div>
      </div>
      <div className='row row-cols-1 mt-2 gx-2'>
        <div className='col'>
          <ResultTable
            className={box_classname}
            hidden={resultIsHidden}
            rankedData={rankedVulcanPlotData}
          />
        </div>
      </div>
    </>
  );
}

export default App;
