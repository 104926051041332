import { ItemSearchMulti } from './ItemSearch';

export function DiseasesInputBox({ demo, setDemoModalShow, setValidatedDiseases, inputDiseases, token, api_root }) {
  return (
    <div
      className='border rounded bg-light p-2 mt-3'
      onClick={(demo) ? () => setDemoModalShow(true) : null}
    >
      <h2>Input Your Diseases</h2>
      <div className='py-2'>
        <ItemSearchMulti
          onOutputChangeHandle={(x) => setValidatedDiseases(x)}
          token={token}
          item_type="disease"
          defaultInput={(inputDiseases != null & inputDiseases !== "") ? inputDiseases.split(',') : []}
          api_root={api_root}
          demo={demo}
        ></ItemSearchMulti>
      </div>
    </div>
  );
}
