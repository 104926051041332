import Plot from "react-plotly.js";
import { HelpButton } from "./HelpButton";

export function ResultPlot({ data, logx, logy, hidden, className}) {

  const dataMaxLogFC = Math.max(...data.map(x=>Math.abs(x['logFC'])))

  const result_plot_help_content = <div>
    <p>
      The Result plot shows the same information included in the Ranked Volcano Plot but in a different way, with a focus on the correlation (Ranking Score) predicted by the GeneRecommender.
    </p>
    <p>
      Indeed, this plot has on the x-axis the ranking score (how much the AI consider a specific gene related to the input set), on the y-axis the p-value, while the color scale and the dot size represent respectively the sign and the magnitude of the Log Fold Change.
    </p>
  </div>
  
  

  return (
    <div className={className} hidden={hidden}>
      <div className="d-flex">
        <h2>Result Plot</h2>
        <span className="mx-auto"></span>
        <div>
          <HelpButton
            className='ms-1' 
            title='Result Plot Help'
            content={result_plot_help_content}
          />
        </div>
      </div>
      {data ? <Plot
        data={[
          {
            x: data.map(x=>x['score']),
            y: data.map(x=>x['-log10(p)']),
            hovertext: data.map(x=>x['symbol']),
            type: 'scatter',
            mode: 'markers',
            marker: { 
              color: data.map(x=>x['logFC']) ,
              size: data.map(x=>20*Math.abs(x['logFC'])/dataMaxLogFC),
              colorbar : {
                title : 'logFC'
              },
            }
          }
        ]}
        layout={{
          autosize: true,
          xaxis: {
            title: 'Ranking Score',
            type: logy ? 'log' : null,
            autorange: true
          },
          yaxis: {
            title: '-log10(p)',
            type: logx ? 'log' : null,
            autorange: true
          }
        }} 
      /> : <></>}
    </div>
  );
}
