import { Button, Modal, Alert } from 'react-bootstrap'
import { GenesFinder } from "./GenesFinder"
import { useState } from 'react';




export function GeneValidationModal(props) {

  const [disableDoneBtn, setDisableDoneBtn] = useState(true)
  const [alertShow, setAlertShow] = useState(false)

  const handleDone = () => {
    // If all gene have been recognised => close. Otherwise show alert!
    if (props.notValidGenes.length > 0) {
      setAlertShow(true) 
    } else {
      // console.log(props.genes_list)
      // console.log(props.validatedGenes)
      props.setInputGenes(props.validatedGenes.join('\n'))
      props.onHide()
    } 
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Gene Validation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='flex'>
        <GenesFinder
          genes_list={props.genes_list}
          token={props.token}
          validatedGenes={props.validatedGenes}
          setValidatedGenes={props.setValidatedGenes}
          notValidGenes={props.notValidGenes}
          setNotValidGenes={props.setNotValidGenes}
          api_root={props.api_root}
          onGenesValidation={setDisableDoneBtn}
        />
      </Modal.Body>
      <Modal.Footer>
        <Alert variant="danger" show={alertShow} onClose={setAlertShow} dismissible>
          {/* <Alert.Heading>Oh snap! You got an error!</Alert.Heading> */}
          <p>
            Some genes still need to be validated! Please go through all genes in the list above.
          </p>
        </Alert>
        <Button 
          className='mr-1'
          onClick={
            handleDone
          }
          disabled={disableDoneBtn}
        >Done</Button>
      </Modal.Footer>
    </Modal>
  )
}





