import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const el = document.getElementById('vulcan-plot-root')

const root = ReactDOM.createRoot(el);
root.render(
  <React.StrictMode>
    <App
      token={el.getAttribute('token')}
      api_root={el.getAttribute('api_root')}
      debug={el.getAttribute('debug')!==null}
    />
  </React.StrictMode>
)

