import { useState } from "react";

export function ThresholdSlider({ label, value, onChange, min, max, step }) {
  const [_th, _setTh] = useState(value);
  return (
    <div>
      <label htmlFor="customRange1" className="form-label">{`${label}`}<span className="badge bg-primary ms-1">{_th}</span></label>
      <div className="d-flex">
        <input
          className="form-range"
          type="range"
          min={min}
          max={max}
          step={step}
          value={_th}
          id="customRange1"
          onChange={(x) => {
            _setTh(x.target.value);
            onChange(x.target.value);
          }} />
      </div>
    </div>
  );
}
