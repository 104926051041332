import { Button } from 'react-bootstrap';

export function SubmitButton({ genesList, handleSubmit }) {
  return (
    <Button
      className={`col w-100 mt-2`}
      disabled={genesList ? genesList.length < 1 : true}
      onClick={() => handleSubmit(genesList)}
      variant='success'
    >Submit</Button>
  );
}
