import { useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { CheckLg, XLg } from 'react-bootstrap-icons'
import ItemSelector from './ItemSearch'


async function validateGenes(
  inputGenes,
  token,
  api_root
) {

  const lowerInputGenes = inputGenes.map((x)=>x.toLowerCase())
  // const querystring = lowerInputGenes.map((g)=>{return `genesymbol=${g}`}).join('&')
  const body_data = JSON.stringify({
    "geneslist" : lowerInputGenes
  })

  return fetch(
  `${api_root}/genes/search/multi/?withalias=false&withinfolink=false`,
  {
    method: 'POST',
    headers: {
      'authorization' : `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: body_data
  }
)
.then(response => response.json())
.then((data)=>{
  return {
    reco_genes : data.gene_info_list.map((x)=>{return x.genesymbol}), 
    not_reco_genes : data.not_reco_genes
  }
})

}

export function GenesFinder({ genes_list, validatedGenes, setValidatedGenes, notValidGenes, setNotValidGenes, token, api_root, onGenesValidation }) {

  function addValidatedGene(g) {
    // console.log(validatedGenes)
    // console.log(g)
    // console.log(setValidatedGenes)
    setValidatedGenes(validatedGenes.concat([g]))
  }

  function removeNotValidGene(g) {
    // console.log('remove not valid gene')
    // console.log(g)
    // console.log('from')
    // console.log(notValidGenes)
    setNotValidGenes(notValidGenes.filter((x)=>{return (x !== g)}))
  }

  const [hideSpinner, setHideSpinner] = useState(false)
  const [hideValidation, setHideValidation] = useState(true)

  useEffect(
    () => {
      let processed_genes_list = genes_list.trim().split('\n').map((x)=>x.trim()).filter((x)=>x!=="")
      let unique_processed_genes_list = [...new Set(processed_genes_list)]
      validateGenes(unique_processed_genes_list, token, api_root).then(
        (x) => {
          setValidatedGenes(x.reco_genes)
          setNotValidGenes(x.not_reco_genes)
          setHideSpinner(true)
          setHideValidation(false)
          onGenesValidation(false)
        }
      )
    },
    [genes_list, token, api_root, setValidatedGenes, setNotValidGenes, onGenesValidation]
  )
  
  return (
    <div>
      <div className="d-flex justify-content-center" >
        <Spinner animation="border" role="status" hidden={hideSpinner} variant='primary' >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
      <div hidden={hideValidation}>
      {
        (notValidGenes.length > 0) ?
        notValidGenes.map(
          (g) => (
            <GeneValidatorLine 
              key={g}
              g={g}
              addValidatedGene={addValidatedGene}
              removeNotValidGene={removeNotValidGene}
              token={token}
              api_root={api_root}
            />
          )
        ) : <div className='text-success text-center'>VALIDATION DONE!</div>
      }
      </div>
    </div>
  )
}



function GeneValidatorLine({g, addValidatedGene, removeNotValidGene, token, api_root}) {

  const [selectedGene, setSelectedGene] = useState(null)
  // console.log(selectedGene)
  // console.log('selected gene')
  // console.log(selectedGene)
  // const [validGene, setValidGene] = useState()
  // console.log(validGene)
  const [selectorDisable, setSelectorDisable] = useState(false)
  
  function handleCheck(e) {
    // console.log('check')
    setSelectorDisable(true)
    // setCheckDisplay("none")
    addValidatedGene(selectedGene)
    removeNotValidGene(g)    
  }
  function handleDelete(e) {
    // console.log('delete')
    removeNotValidGene(g)
  }

  return (
    <div className='d-flex flex-row my-1' key={g}>
      <Button className='mx-1 w-25' variant='light' key={g}>{g}</Button>
      <div className='w-75'>
        <ItemSelector
          token={token}
          q={g}
          key={`${g}-sel`}
          onchange={setSelectedGene}
          isDisabled={selectorDisable}
          api_root={api_root}
        ></ItemSelector>
      </div>
      <Button 
        className='mx-1 btn-success' 
        key={`${g}-check`}  
        disabled={selectedGene === ''}
        onClick={(e)=>handleCheck(e)}
      ><CheckLg/></Button>
      <Button 
        className='btn-danger'  
        key={`${g}-delete`} 
        onClick={(e)=>handleDelete(e)}
      ><XLg/></Button>
    </div>
  )
  
}