import { Upload } from "react-bootstrap-icons";
import { useRef } from "react";

export function FileUploadButton({ onFileUpload, className }) {

  const inputEl = useRef(null)

  return (
    <label htmlFor='file-upload' className={`btn btn-outline-primary ${className}`}>File Upload<Upload className="ms-1"/> 
      <input
        id='file-upload'
        ref={inputEl}
        className="hidden-file-upload"
        type='file'
        onChange={
          (e) => {
            onFileUpload(e.target.files[0])
            inputEl.current.value = null
          }
        }
        accept='.csv,.tsv' 
      />
    </label>
  );
}
