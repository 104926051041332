import { useState } from "react";
import { ThresholdSlider } from "./ThresholdSlider";
import { Button } from "react-bootstrap";

export function AutoInput({ data, defaultTh, handleSubmit }) {

  const finiteAbsLogFC = data.filter(x => Number.isFinite(x.logFC)).map(x => Math.abs(x.logFC));
  const finiteAbsPval = data.filter(x => Number.isFinite(x['-log10(p)'])).map(x => x['-log10(p)']);
  const minLogFC = Math.min(...finiteAbsLogFC);
  const maxLogFC = Math.max(...finiteAbsLogFC);
  const minPvalue = Math.min(...finiteAbsPval);
  const maxPvalue = Math.max(...finiteAbsPval);

  const [th, setTh] = useState(defaultTh);
  const modTh = th * 0.15 + 0.7; // this values just happen to work by magic!



  // console.log(th)
  const input_genes = data.filter(
    (x) => {
      let normLogFC = (Math.abs(x.logFC) - minLogFC) / maxLogFC;
      let normPval = (x['-log10(p)'] - minPvalue) / maxPvalue;
      return Math.sqrt(normLogFC * normPval) > (1 - modTh); // this values just happen to work by magic!
    }
  ).map((x) => x.symbol);
  // console.log(input_genes)
  return (
    <div className='p-2 flex'>
      <ThresholdSlider
        label='Sensitivity'
        value={defaultTh}
        onChange={setTh}
        min={0}
        max={1}
        step={0.05}
      ></ThresholdSlider>
      <Button variant='success' className="w-100 mt-2" onClick={(e) => { handleSubmit(input_genes); }}>Submit</Button>
    </div>
  );
}
