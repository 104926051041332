import { debounce } from "lodash";
import Plot from "react-plotly.js";
import { ThresholdSlider } from "./ThresholdSlider";
import { HelpButton } from './HelpButton';

export function VulcanPlot({className, data, outputData, thX, setThX, thY, setThY, logfc_max, pvalue_max, hidden}) {

  const _setThX = debounce(setThX, 300)
  const _setThY = debounce(setThY, 300)

  const markerMap = {
    'input' : 'star',
    'not_reco' : 'square',
    'ranked' : 'circle'
  }
  const colorMap = {
    'input' : 'red',
    'not_reco' : 'grey',
    'ranked' : 'YlGnBu'
  }
  const opacityMap = {
    'input' : 1.0,
    'not_reco' : 0.3,
    'ranked' : 1.0
  }

  const vulcanData = {
    name: 'data',
    x: data.map(x=>x['logFC']),
    y: data.map(x=>x['-log10(p)']),
    text: data.map(x=>x['symbol']),
    type: 'scatter',
    mode: 'markers',
    marker: { 
      color: data.map(x=>x['color']),
      colorbar : {
        title : 'logFC'
      }
    }
  }

  const outputDataInput = outputData.filter(x => x.type==='input')
  const outputDataNotReco = outputData.filter(x => x.type==='not_reco')
  const outputDataRanked = outputData.filter(x => x.type==='ranked')

  const rankedData = {
    name: 'ranked',
    x: outputDataRanked.map(x=>x['logFC']),
    y: outputDataRanked.map(x=>x['-log10(p)']),
    text: outputDataRanked.map(x=>x['symbol']),
    type: 'scatter',
    mode: 'markers',
    marker: { 
      color: outputDataRanked.map(x=>x['color']) ,
      colorscale: colorMap['ranked'],
      colorbar : {
        title : 'Score'
      },
      symbol: markerMap['ranked'],
      opacity: opacityMap['ranked']
    }
  }

  const notRecoData = {
    name: 'not_reco',
    x: outputDataNotReco.map(x=>x['logFC']),
    y: outputDataNotReco.map(x=>x['-log10(p)']),
    text: outputDataNotReco.map(x=>x['symbol']),
    type: 'scatter',
    mode: 'markers',
    marker: { 
      color: colorMap['not_reco'],
      symbol: markerMap['not_reco'],
      opacity: opacityMap['not_reco']
    }
  }
  const inputData = {
    name: 'input',
    x: outputDataInput.map(x=>x['logFC']),
    y: outputDataInput.map(x=>x['-log10(p)']),
    text: outputDataInput.map(x=>x['symbol']),
    type: 'scatter',
    mode: 'markers',
    marker: { 
      color: colorMap['input'],
      symbol: markerMap['input'],
      opacity: opacityMap['input']
    }
  }

  const plotDataArray = []

  if (outputData.length === 0) {
    plotDataArray.push(vulcanData)
  } else {
    plotDataArray.push(rankedData)
    plotDataArray.push(inputData)
    plotDataArray.push(notRecoData)
  }
  

  // console.log(plotDataArray)

  return (
    <div className={className} hidden={hidden}>
      <div className="d-flex">
        <h2>Volcano Plot</h2>
        <span className="mx-auto"></span>
        <div>
          <HelpButton 
            className='ms-1' 
            title='Volcano Plot Help'
            content={'Classic Volcano Plot of your data. X and Y thresholds can be adjusted with the two sliders. Only datapoints that are above the two thresholds will be shown and considered for the subsequent analysis.'}
          />
        </div>
      </div>
      <ThresholdSlider
        label='x threshold'
        value={thX}
        onChange={_setThX}
        min={0}
        max={logfc_max}
        step={0.1}
      />
      <ThresholdSlider
        label='y threshold'
        value={thY}
        onChange={_setThY}
        min={0}
        max={pvalue_max}
        step={0.1}
      />
      
      <Plot
        data={plotDataArray}
        layout={{
          autosize: true,
          xaxis: {
            title: 'logFC',
            // type: 'log',
            autorange: true
          },
          yaxis: {
            title: '-log10(p)',
            // type: 'log',
            autorange: true
          },
          showlegend:true,
          legend: {
            orientation : "h",
            yanchor : "bottom",
            y : 1.02,
            xanchor : "right",
            x : 0.5
          }
        }} 
      />
    </div>
  );
}

const myColorScale = [
  ['0.00','rgb(212, 238, 192)'],
  ['0.16','rgb(247, 252, 217)'],
  ['0.33','rgb(208, 237, 189)'],
  ['0.50','rgb(114, 200, 188)'],
  ['0.67','rgb(47, 164, 194)'],
  ['0.83','rgb(34, 94, 168)'],
  ['1.00','rgb(12, 32, 97)']
]


export function RankedVulcanPlot({className, data, hidden}) {

  // console.log(data)

  

  const markerMap = {
    'input' : 'star',
    'not_reco' : 'square',
    'ranked' : 'circle'
  }
  const colorMap = {
    'input' : 'red',
    'not_reco' : 'grey',
    'ranked' : myColorScale
  }
  const opacityMap = {
    'input' : 1.0,
    'not_reco' : 0.3,
    'ranked' : 1.0
  }

  const outputDataInput = data.filter(x => x.type==='input')
  const outputDataNotReco = data.filter(x => x.type==='not_reco')
  const outputDataRanked = data.filter(x => x.type==='ranked')

  const rankedData = {
    name: 'ranked',
    x: outputDataRanked.map(x=>x['logFC']),
    y: outputDataRanked.map(x=>x['-log10(p)']),
    text: outputDataRanked.map(x=>x['symbol']),
    type: 'scatter',
    mode: 'markers',
    marker: { 
      color: outputDataRanked.map(x=>(x['color'])) ,
      colorscale: colorMap['ranked'],
      colorbar : {
        title : 'Score'
      },
      symbol: markerMap['ranked'],
      opacity: opacityMap['ranked']
    }
  }

  const notRecoData = {
    name: 'not_reco',
    x: outputDataNotReco.map(x=>x['logFC']),
    y: outputDataNotReco.map(x=>x['-log10(p)']),
    text: outputDataNotReco.map(x=>x['symbol']),
    type: 'scatter',
    mode: 'markers',
    marker: { 
      color: colorMap['not_reco'],
      symbol: markerMap['not_reco'],
      opacity: opacityMap['not_reco']
    }
  }
  const inputData = {
    name: 'input',
    x: outputDataInput.map(x=>x['logFC']),
    y: outputDataInput.map(x=>x['-log10(p)']),
    text: outputDataInput.map(x=>x['symbol']),
    type: 'scatter',
    mode: 'markers',
    marker: { 
      color: colorMap['input'],
      symbol: markerMap['input'],
      opacity: opacityMap['input']
    }
  }

  const plotDataArray = []

  if (data.length !== 0) {
    plotDataArray.push(rankedData)
    plotDataArray.push(inputData)
    plotDataArray.push(notRecoData)
  }
  
  const ranked_vulcan_help_content = <div>
    <p>
    The Ranked Volcano Plot displays the combined information from the original volcano plot and the GeneRecommender predictions. 
    </p>
    <p>
      In detail, data points will keep the same position as in the previous graph but their color scale will represent how much the algorithm considers a specific gene to be related to the input set. The darker the gene, the higher the predicted correlation. 
    </p>
    <p>  
      Red starred points are genes that have been included in the input set and, for this reason, cannot be ranked. Grey squares are genes with gene symbols that are not recognised as human genes by the algorithm.
    </p>
  </div>
  // console.log(plotDataArray)

  return (
    <div className={className} hidden={hidden}>
      <div className="d-flex">
        <h2>Ranked Volcano Plot</h2>
        <span className="mx-auto"></span>
        <div>
          <HelpButton 
            className='ms-1' 
            title='Ranked Volcano Plot Help'
            content={ranked_vulcan_help_content}
          />
        </div>
      </div>
      <Plot
        data={plotDataArray}
        layout={{
          autosize: true,
          xaxis: {
            title: 'logFC',
            // type: 'log',
            autorange: true
          },
          yaxis: {
            title: '-log10(p)',
            // type: 'log',
            autorange: true
          },
          showlegend:true,
          legend: {
            orientation : "h",
            yanchor : "bottom",
            y : 1.02,
            xanchor : "right",
            x : 0.5
          }
        }} 
      />
    </div>
  );
}
