import { Tabs, Tab } from "react-bootstrap";
import { AutoInput } from "./AutoInput";
import GeneSetInput from './GeneSetInput'
import { CustomInput } from "./CustomInput";
import { HelpButton } from "./HelpButton";



async function getGeneRanking(api_root, token, genesList, diseasesList, genesToRank, scoreMod) {
  
  // console.log('function call getGeneRanking')
  
  const fetch_url = `${api_root}/recommend/rank/?algo=gene_disease_V2_experimental&score_mod=${scoreMod===true}`
  const recommendBody = {
    "geneslist": genesList,
    "diseaseslist": diseasesList,
    "meshlist": [],
    "genestorank": genesToRank
  }
  
  const rankedGenesList = fetch(fetch_url,
    {
      method: 'POST',
      headers: {
        'authorization' : `Bearer ${token}`,
        'Accept': 'application/json',
        'content-type': 'application/json'
      },
      body : JSON.stringify(recommendBody)
    }
  )
  .then(response => response.json())

  return rankedGenesList
}

export function InputBox({ className, data, setRankedData, api_root, token, hidden, postSubmitHandle}) {
  
  const handleSubmit = (inputGenes, inputDiseases = []) => {
    // console.log('handleSubmit function call')
    const copyData = data.map(object => ({ ...object })) // copy without reference all objects
    const copyDataInput = copyData.filter(x=>inputGenes.includes(x.symbol))
    const copyDataToRank = copyData.filter(x=>!inputGenes.includes(x.symbol))
    const genesToRank = copyDataToRank.map(x=>x.symbol)

    getGeneRanking(api_root, token, inputGenes, inputDiseases, genesToRank, true).then(
      rankedList => {
        copyDataInput.forEach(x => x.type = 'input')
        const rankedGenes = rankedList.map(x=>x.gene)
        const notRecoGenes = genesToRank.filter(x => !rankedGenes.includes(x))
        // console.log('rankedGenes',rankedGenes)
        // console.log('notRecoGenes',notRecoGenes)
        copyDataInput.forEach(x => {
          x.type = 'input'
        })
        const copyDataNotReco = copyData.filter(x=>notRecoGenes.includes(x.symbol))
        copyDataNotReco.forEach(x => {
          x.type = 'not_reco'
        })
        const copyDataRanked = copyData.filter(x=>rankedGenes.includes(x.symbol))
        // const rankedSum = rankedList.map(x=>x.score).reduce((a,b)=>a+b)
        // const rankedMean = rankedSum/rankedList.length
        const rankedMax = Math.max(...rankedList.map(x=>x.score))
        copyDataRanked.forEach(x => {
          const sym = x.symbol
          const scr = rankedList.find(k=> k.gene===sym).score
          x.type = 'ranked'
          x.score = scr
          x.normscore = scr/rankedMax
          x.color = scr
        })
        // console.log(copyData)
        setRankedData(copyData)
      }
    )
    postSubmitHandle()
  }

  const help_content = <div className="text-justify">
    The Input box allows the user to interact with the Generecommender and combine AI predictions with the Volcano Plot. It is possible to choose the input (genes only) in four different ways:
    <ul>
      <li>Pathways</li>
      <li>Diseases</li>
      <li>Auto</li>
      <li>Custom</li>
    </ul>
    <h6 className="mt-2">Pathways and Diseases</h6>
    With this pane, the user will be able to select a set of genes (to be used as input) that are known to be related to a specific pathway (or disease).
    Once a specific pathway is selected the related genes will be shown in the box below. 
    Click submit to proceed.

    <h6 className="mt-2">Auto</h6>
    With this pane, the user will be able to use the outer genes in the cloud of datapoints as input to ask a prediction on the others. The number of genes that will be included in the input is managed by the “Sensitivity” parameter that can be adjusted by the slider.
    Click submit to proceed.

    <h6 className="mt-2">Custom</h6>
    With this pane, the user can manually insert the list of genes to be used as input.
  </div>

  return (
    <div className={className} hidden={hidden}>
      <div className="d-flex">
        <h2>Input Box</h2>
        <span className="mx-auto"></span>
        <div>
          <HelpButton
            className='ms-1' 
            title='Input Box Help'
            content={help_content}
          />
        </div>
      </div>
      <Tabs fill>
        <Tab eventKey='Pathway' title='Pathways'>
          <GeneSetInput
            source='pathway'
            source_name='Pathways'
            token={token}
            api_root={api_root}
            handleSubmit={handleSubmit}
          ></GeneSetInput>      
        </Tab>
        <Tab eventKey='Diseases' title='Diseases'>
          <GeneSetInput
            source='disease'
            source_name='Diseases'
            token={token}
            api_root={api_root}
            handleSubmit={handleSubmit}
          ></GeneSetInput>   
        </Tab>
        <Tab eventKey='Auto' title='Auto'>
          <AutoInput
            data={data}
            defaultTh={0.5}
            handleSubmit={handleSubmit}
          ></AutoInput>
        </Tab>
        <Tab eventKey='Custom' title='Custom'>
          <CustomInput
            token={token}
            api_root={api_root}
            handleSubmit={handleSubmit}
          />
        </Tab>
      </Tabs>  
    </div>
  );
}
