import { CSVLink } from 'react-csv'
import './custom.css'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';

import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import { Download } from 'react-bootstrap-icons';

export function ResultTable({ className, rankedData, hidden }) {

  const downloadData = rankedData.map(
    (record) => {
      return {
        'genesymbol' : record['symbol'],
        'logFC' : record['logFC'],
        '-log10(p)' : record['-log10(p)'],
        'Type' : record.type,
        'Score' : record['score']
      }
    }
  )


  const logFcBodyTemplate = (rowData) => {
    return rowData.logFC.toFixed(2)
  }

  const pValBodyTemplate = (rowData) => rowData['-log10(p)'].toFixed(2)

  const scoreBodyTemplate = (rowData) => {
    if (rowData['score']) {
      return rowData['score'].toFixed(2)
    } else {
      return rowData['score']
    }
    
  }

  const filters = {
    'symbol': { matchMode: FilterMatchMode.CONTAINS },
    'logFC': { matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO},
    '-log10(p)': { matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO},
    'type': { matchMode: FilterMatchMode.CONTAINS},
    'score': { matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO},
  }

  
  return (
    <div className={className} hidden={hidden}>
      <div className='d-flex mb-1'>
        <h2>Result Table</h2>
        <span className="mx-auto"></span>
        <div>
          <div className='d-flex'>
            <CSVLink data={downloadData} className='btn btn-info ms-auto' filename={'results.csv'}>Download CSV <Download>Z</Download></CSVLink>
            {/* <HelpButton
              className='ms-1' 
              title='Result Table Help'
              content={'help_content'}
            /> */}
          </div>
        </div>
      </div>
      <div className='overflow-auto' >
      <DataTable 
        value={rankedData} 
        responsiveLayout="scroll" 
        paginator 
        rows={10}
        sortField='score'
        sortOrder={-1}
        filters={filters}
        filterDisplay="row"
      >
          <Column dataType='text' field='symbol' header='GeneSymbol' sortable filter ></Column>
          <Column dataType='numeric' field='logFC' header='logFC' sortable filter body={logFcBodyTemplate}></Column>
          <Column dataType='numeric' field='-log10(p)' header='-log10(p)' sortable filter body={pValBodyTemplate} ></Column>
          <Column dataType='text' field='type' header='type' sortable filter ></Column>
          <Column dataType='numeric' field='score' header='score' sortable filter body={scoreBodyTemplate}></Column>
      </DataTable>
        {/* <Table striped bordered hover size="sm" responsive >
          <thead>
            <tr>
              <th>GeneSymbol</th>
              <th>logFC</th>
              <th>-log10(p)</th>
              <th>type</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            
            {
              rankedData ? rankedData.map(
                r => (<tr>
                      <td>{r.symbol}</td>
                      <td>{r.logFC}</td>
                      <td>{r['-log10(p)']}</td>
                      <td>{r.type}</td>
                      <td>{r.score}</td>
                    </tr>)
              )
              : (
                <tr>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                </tr>
              )
            } 
          </tbody>
        </Table> */}
      </div>
    </div>
  );
}
