import Papa from 'papaparse';
import { useState } from 'react';
import { FileDetails } from './FileDetails';
import { FilePreprocessingModal } from './FilePreprocessingModal';
import './FileUpload.css'
import { FileUploadButton } from './FileUploadButton';
import { Button } from 'react-bootstrap';
import { Download } from 'react-bootstrap-icons';
import { HelpButton } from './HelpButton';

const preprocessData = (data) => {

  let processed_data = data.map(
    (d) => {
      let processed_d = {
        'logFC'   : parseFloat(d['logFC']),
        '-log10(p)' : parseFloat(-Math.log10(d['-log10(p)'])),
        'symbol'  : d['symbol']?.toLowerCase()
      }

      return processed_d
    }
  )

  return processed_data
}

function InstructionsBox(props) {
  return (
    <div className='card p-2 bg-light'>
      <p className='mt-2'>This is the interface of the VulcanPlotAI that integrates the <a href='https://www.generecommender.com' target='_blank' rel='noreferrer'>GeneRecommender</a> technology in a Volcano Plot analysis for differential expression data.</p> 
      <p>To understand how each component works click on the related help button (?). </p>
      <p>To test the system use your own data or the sample file that can be downloaded here below.</p>
      <a href='https://storage.googleapis.com/tpai-js-deploy/vulcan-plot-ai/data/vulcan_demo.csv' type='download'><Button>Sample File <Download></Download></Button></a>
    </div>
  )
}


export function FileUpload({ className, setSelVulcanPlotData, setVulcanPlotIsHidden, setInputBoxIsHidden, setResultIsHidden }) {

  const [vulcanPlotRawData, setVulcanPlotRawData] = useState([])
  const [vulcanPlotRawFields, setVulcanPlotRawFields] = useState([])
  const [logfcMapping, setLogfcMapping] = useState(null)
  const [pvalueMapping, setPvalueMapping] = useState(null)
  const [symbolMapping, setSymbolMapping] = useState(null)
  const [uploadedFile, setUploadedFile] = useState(null)
  const [filePreprocessingModalShow, setFilePreprocessingModalShow] = useState(false)
  
  const handleFilePreprocessingModalClose = () => setFilePreprocessingModalShow(false)
  const handleShow = () => setFilePreprocessingModalShow(true);

  const onCompletedUpload = (r) => {
    setVulcanPlotRawData(r.data)
    setVulcanPlotRawFields(r.meta.fields)
  };

  const handleFilePreprocessingModalSubmit = (x) => {
    const renamedData = renameFields(vulcanPlotRawData)
    setSelVulcanPlotData(preprocessData(renamedData))
    setVulcanPlotIsHidden(false)
    setInputBoxIsHidden(false)
    setFilePreprocessingModalShow(false)
  }

  const onFileUpload = (file) => {
    Papa.parse(
      file,
      {
        header: true,
        complete: onCompletedUpload,
        dynamicTyping: true,
        skipEmptyLines: true
      }
    );
    // console.log('parsing success')
  };

  const onFileSelect = (file) => {
    setResultIsHidden(true)
    setVulcanPlotIsHidden(true)
    setInputBoxIsHidden(true)
    setVulcanPlotRawData([])
    setVulcanPlotRawFields([])
    setLogfcMapping(null)
    setPvalueMapping(null)
    setSymbolMapping(null)
    onFileUpload(file)
    handleShow()
  }

  const onFileRemove = () => {
    setUploadedFile(null)
    setVulcanPlotRawData([])
    setVulcanPlotRawFields([])
    setLogfcMapping(null)
    setPvalueMapping(null)
    setSymbolMapping(null)
    setResultIsHidden(true)
    setVulcanPlotIsHidden(true)
    setInputBoxIsHidden(true)
  }

  const renameFields = (data) => {   
    
    data.forEach(
      (x)=>{
        x['logFC'] = x[logfcMapping]
        x['-log10(p)'] = x[pvalueMapping]
        x['symbol'] = x[symbolMapping]
      }
    )
    return data
  }

  const fileUploaded = (uploadedFile === null)

  const help_content = <div>
    Click on <i>File Upload</i> and upload the file with your gene expression data.
    The file should be a <i>.csv</i> file with these three columns:
    <ul>
      <li>GeneSymbol (text)</li>
      <li>LogFC (float)</li>
      <li>P-Value or Adj P-Value (float)</li>
    </ul>
    Column names are not given, they will be mapped in the following steps.
  </div>

  return (
    <div className={className}>
      <h3>Vulcan Plot AI Dashboard</h3>
      <div className='row row-cols-2'>
        <div className='col'>
          <InstructionsBox/>
        </div>
        <div className='col'>
          <div hidden={false} className='d-flex'>
            <FileUploadButton
              className='w-100'
              onFileUpload={setUploadedFile}
            />
            <HelpButton 
              className='ms-1' 
              title='Upload Help'
              content={help_content}
            />
          </div>
          <FileDetails
            uploadedFile={uploadedFile}
            hidden={fileUploaded}
            onSelect={onFileSelect}
            onRemove={onFileRemove}
          />
          <FilePreprocessingModal 
            show={filePreprocessingModalShow}
            handleClose={handleFilePreprocessingModalClose}
            setLogfcMapping ={setLogfcMapping}
            logfcMapping={logfcMapping}
            setPvalueMapping ={setPvalueMapping}
            pvalueMapping={pvalueMapping}
            setSymbolMapping ={setSymbolMapping}
            symbolMapping={symbolMapping}
            vulcanPlotRawData ={vulcanPlotRawData}
            vulcanPlotRawFields ={vulcanPlotRawFields}
            handleSubmit ={handleFilePreprocessingModalSubmit}
          />
        </div>
      </div>
    </div>
  );
}


