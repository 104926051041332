import { useState } from 'react';
import { Button, Modal, Row, Col, Alert } from 'react-bootstrap';
import { HeaderSelector } from './HeaderSelector';
import { TablePreview } from './TablePreview';

export function FilePreprocessingModal({
    show, handleClose, setLogfcMapping, logfcMapping, setPvalueMapping, pvalueMapping, setSymbolMapping, symbolMapping, vulcanPlotRawData, vulcanPlotRawFields, handleSubmit
  }) {
  
  const [nullData, setNullData] = useState([])
  const [nullFields, setNullFields] = useState([])
  const [dataIsOk, setDataIsOk] = useState(null)

  // console.log(dataIsOk)

  const options = vulcanPlotRawFields.map((x) => {
    return {
      'value': x,
      'label': x
    };
  })

  const checkData = async (data) => {

    let nullFieldsTemp = []
    // console.log(data.slice(0,5))
    const symbolNullData = data.filter(x=>(x[symbolMapping] === null))
    if ( symbolNullData.length > 0 ) {nullFieldsTemp.push(symbolMapping)}
    // console.log('symbolNullData:', symbolNullData)
    const logfcNullData = data.filter(x=>(x[logfcMapping] === null))
    if ( logfcNullData.length > 0 ) {nullFieldsTemp.push(logfcMapping)}
    // console.log('logfcNullData:', logfcNullData)
    const pvalueNullData = data.filter(x=>(x[pvalueMapping] === null))
    if ( pvalueNullData.length > 0 ) {nullFieldsTemp.push(pvalueMapping)}
    // console.log('pvalueNullData:', pvalueNullData)
    
    setNullData(symbolNullData.concat(logfcNullData, pvalueNullData))
    setNullFields(nullFieldsTemp)
    
    if (nullFieldsTemp.length === 0) {
      setDataIsOk(true)
    } else {
      // console.log('Empty Fields: ', nullFieldsTemp)
      setDataIsOk(false)
    }
  }

  const resetData = () => {
    setNullData([]) 
    setNullFields([])
    setDataIsOk(null)
  }

  const resetMappings = () => {
    setLogfcMapping(null)
    setPvalueMapping(null)
    setSymbolMapping(null)
  }

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header>
        <div className='flex mb-1'>
          <Modal.Title>File Preprocessing</Modal.Title>
          For each requested field, select the corresponding column name in your file and click on <i>check data</i>. 
          If the file is correct (i.e. no missing value, no wrong type etc.) you can submit it. Otherwise, you should re-upload the file with the corrections.
        </div>
        
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <HeaderSelector
              label="Log Fold Change"
              setHeader={setLogfcMapping}
              options={options} />
          </Col>
          <Col>
            <HeaderSelector
              label="P-Value"
              setHeader={setPvalueMapping}
              options={options} />
          </Col>
          <Col>
            <HeaderSelector
              label="Gene Symbol"
              setHeader={setSymbolMapping}
              options={options} />
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col>
            <TablePreview
              data={vulcanPlotRawData.slice(0, 100)}
              fields={vulcanPlotRawFields} 
              maxrows={4}
            />
          </Col>
        </Row>
        {
          nullFields.map(c => <Alert key={c} variant='warning' >Warning: {c} column contains null values. Please check for corrupted lines in the following table.</Alert>)
        }
        <Row>
          <Col hidden={nullData.length === 0}>
            <TablePreview
              data={nullData}
              fields={vulcanPlotRawFields} 
              maxrows={4}
            />
          </Col>
        </Row>
        <Alert variant='success' hidden={dataIsOk === false | dataIsOk === null}>Success: data is ok to proceed, please submit.</Alert>
        <Alert variant='danger' hidden={dataIsOk === true | dataIsOk === null}>Error: data is not ok to proceed. Please fix the errors in the uploaded file and start again.</Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={
            ()=>{
              handleClose()
              resetData()
              resetMappings()
            }
          }
          className='w-100'
          hidden={dataIsOk === true | dataIsOk === null}
        >Close and Reset</Button>
        <Button
          variant="success"
          onClick={
            ()=>{
              handleSubmit()
              resetData()
            }
          }
          className='w-100'
          disabled={(logfcMapping === null | pvalueMapping === null | symbolMapping === null)}
          hidden={!dataIsOk}
        >Submit</Button>
        <Button
          variant="outline-primary"
          onClick={
            ()=>{
              checkData(vulcanPlotRawData)
            }
          }
          className='w-100'
          hidden={(dataIsOk !== null) | (logfcMapping === null) | (symbolMapping === null) | (pvalueMapping === null)}
        >Check Data</Button>
      </Modal.Footer>
    </Modal>
  );

}
