import { Button, Form } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import SampleFileDownloader from './SampleFileDownloader';

export function GenesInputBox({demo, setDemoModalShow, setGeneSearchModalShow, genesToValidate, setGenesToValidate}) {
  
  const onFileUpload = (f) => {
    
    const file = new FileReader()
    file.readAsText(f, 'utf-8')
    file.onload = () => {
      setGenesToValidate(file.result)
    }
    file.onerror = () => {
      // console.log('error loading file!')
    }
  }

  return (
    <div className='flex border rounded bg-light p-2'>
      <div className='d-flex'>
        <h2>Input Your Genes</h2>
        <div className='mx-auto'></div>
        <Button
          onClick={(demo) ? () => setDemoModalShow(true) : () => setGeneSearchModalShow(true)}
        ><Search /> Gene Search</Button>
      </div>
      <div className='d-flex my-2'>
        <div className='w-25'><SampleFileDownloader /></div>
        <div className='mx-1'></div>
        <div
          className='w-75'
          onClick={(demo) ? () => setDemoModalShow(true) : null}
        >
          <input
            className="form-control w-100"
            type='file'
            onChange={(e) => onFileUpload(e.target.files[0])}
            disabled={demo} />
        </div>
      </div>

      <div className='mb-2'>
        <Form.Control
          as="textarea"
          rows={8}
          style={{ resize: 'none', height: 'auto' }}
          placeholder="Enter some gene symbols here, one for each line. Insertion order does not influence the final result."
          onChange={(e) => { setGenesToValidate(e.target.value); }}
          value={genesToValidate} />
      </div>
      {/* <div className='d-flex by-1'>
              <div className='mx-auto'></div>
              <Button onClick={handleValidateButton} hidden={validateHide}>Validate <ListCheck/></Button>
              <Button onClick={handleEdit} hidden={editHide}>Edit Genes <Pencil/></Button>
            </div> */}
    </div>
  );
}
