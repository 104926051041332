import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Question } from 'react-bootstrap-icons';

export function HelpButton({ className, title, content }) {

  const [helpModalShow, setHelpModalShow] = useState(false);

  return (
    <>
      <Button
        className={className}
        variant='outline-info'
        onClick={() => setHelpModalShow(true)}
      ><Question></Question>
      </Button>
      <Modal show={helpModalShow} onHide={() => setHelpModalShow(false)} >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {content}          
        </Modal.Body>
      </Modal>
    </>
  );
}
